.team-page {

    .title-pic {
        min-width: 30em;
        min-height: 30em;
        background-image: url("../images/team-top.jpg?width=600");
        background-size: cover;

        @include media-breakpoint-down(md) {
            min-width: unset;
            width: 100%;
        }

    }

    .biopic {
        float: right;
        width: 20em;
        height: 20em;
        margin: 2em;

        @include media-breakpoint-down(lg) {
            float: none;
            max-width: 70vw;
            max-height: 70vw;
            margin-left: auto;
            margin-right: auto;
        }

        img {
            width: 20em;
            height: 20em;
            border-radius: 50%;
            object-fit: cover;
            object-position: top;

            @include media-breakpoint-down(lg) {
                max-width: 70vw;
                max-height: 70vw;
            }

        }
    }

    .closing {
        min-height: 50vh;

        .splashpic {
            float: right;
            width: 15em;
            height: 20em;
            margin: 2em;

            @include media-breakpoint-down(lg) {
                float: none;
                max-width: 70vw;
                max-height: 70vw;
                margin-left: auto;
                margin-right: auto;
            }

            img {
                width: 15em;
                height: 20em;
                object-fit: cover;
                object-position: center;

                @include media-breakpoint-down(lg) {
                    max-width: 70vw;
                    max-height: 70vw;
                }

            }
        }


    }


}
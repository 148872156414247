/* Color Theme Swatches in Hex */
$GJW-Colour-1-hex: #3154C4;
$GJW-Colour-2-hex: #3472CF;
$GJW-Colour-3-hex: #379AB8;
$GJW-Colour-4-hex: #A3CFCD;
$GJW-Colour-5-hex: #31C496;
$GJW-Colour-5-hex-darker: #1BB2A7;

/* Color Theme Swatches in RGBA */
$GJW-Colour-1-rgba: rgba(49, 84, 196, 1);
$GJW-Colour-2-rgba: rgba(52, 114, 207, 1);
$GJW-Colour-3-rgba: rgba(55, 154, 184, 1);
$GJW-Colour-4-rgba: rgba(163, 207, 205, 1);
$GJW-Colour-5-rgba: rgba(49, 196, 150, 1);

/* Color Theme Swatches in HSLA */
$GJW-Colour-1-hsla: hsla(225, 60%, 48%, 1);
$GJW-Colour-2-hsla: hsla(216, 61%, 50%, 1);
$GJW-Colour-3-hsla: hsla(193, 53%, 46%, 1);
$GJW-Colour-4-hsla: hsla(177, 31%, 72%, 1);
$GJW-Colour-5-hsla: hsla(161, 60%, 48%, 1);

$primary: $GJW-Colour-3-hex;
$secondary: $GJW-Colour-2-hex;
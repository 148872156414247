// Page Specific Changes

// Home Page
.home-page {
    .logo-container {
        position: relative;
        top: -60px;
        z-index: -20;
        margin-bottom: -60px;

        @include media-breakpoint-down(lg) {
            z-index: 0;
            top: -50px;
            margin-bottom: -50px;
        }

    }


    .main-logo {
        width: 33vw;
        max-width: 400px;

        @include media-breakpoint-down(lg) {
            width: 60vw;
        }
    }

    .head-logo {
        display: none;
    }

    #top-landing {
        min-height: 70vh;
        overflow: visible;

        @include media-breakpoint-down(lg) {
            background-image: url("../images/GJW-IN-students-disappearingdome-2020-01-22.jpg?width=1920");
            background-size: cover;
            background-position-y: center;
            background-position-x: 30%;

        }

    }

    .landing-section-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 2em;
        padding-bottom: 5em;
        align-items: center;

        @include media-breakpoint-down(lg) {
            flex-direction: column-reverse;
            gap: 0;
            z-index: -10;

        }

    }

    .landing-section {
        max-width: 30em;
        padding-top: 2em;
        padding-bottom: 2em;


        @include media-breakpoint-down(lg) {
            margin-top: 4em;
            padding: 2em;
            max-width: 80vw;
            background-color: rgba($color: #000000, $alpha: 0.7);
            backdrop-filter: grayscale(70%);

        }

    }

    .landing-headline {
        background: linear-gradient(20deg, #54d0f0 0%, #40ffc3 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        color: transparent;
    }

    .landing-image {
        height: 30em;
        width: 50vw;
        min-width: 30em;
        margin-right: -2em;
        background-image: url("../images/GJW-IN-students-disappearingdome-2020-01-22.jpg?width=1920");
        background-size: cover;
        background-position-y: center;
        background-position-x: 30%;

        @include media-breakpoint-down(lg) {
            display: none;
        }

    }


    // Quotes Section

    .quotes .carousel-item {
        min-height: 25rem;

        .quote-box {
            margin-top: auto;
            margin-bottom: auto;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            gap: 2em;
        }

        .quote-pic {
            background-color: $GJW-Colour-5-hex;
            background-size: cover;
            background-position: center;
            width: 300px;
            height: 300px;
            border-radius: 50%;
            margin-top: auto;
            margin-bottom: auto;
        }


        .quote-jbb {
            background-image: url("../images/JBB.jpg?width=400");
        }

        .quote-rw {
            background-image: url("../images/RW.jpg?width=400");
        }

        .quote-rb {
            background-image: url("../images/RB.jpg?width=400");
        }

        .quote-pc {
            background-image: url("../images/PC.jpg?width=400");
        }

        .quote-bs {
            background-image: url("../images/BS.jpg?width=400");
        }

        .quote-wdp {
            background-image: url("../images/WDP.jpg?width=400");
        }

        .quote-text {
            display: flex;
            flex-direction: column;
            align-items: left;
            justify-content: center;
            max-width: 35vw;

            @include media-breakpoint-down(lg) {
                max-width: 100%;
            }

            .quote-quote {
                font-family: "Source Serif Pro", serif;
                font-weight: 300;
                font-size: xx-large;
                margin-top: 1em;
                line-height: 120%;

                @include media-breakpoint-down(lg) {
                    font-size: large;
                }

            }

            .quote-name {
                text-align: left;
                margin-left: 0.25em;
                font-weight: bolder;
                font-size: larger;
                line-height: 110%;
                margin-bottom: 0;

                .affil {
                    font-size: smaller;
                    font-weight: lighter;
                }
            }
        }

    }

    #closing {
        color: black;
        min-height: 30vh;

        .closing-image {
            background-color: black;
            width: 25em;
            // min-height: 30vw;
            margin-top: 0;
            margin-bottom: 0;
            margin-right: 2.5em;
            background-image: url("../images/Looking\ at\ the\ Monitor\ in\ the\ Observatory.jpg?width=600");
            background-position-x: center;
            background-position-y: center;
            background-size: cover;

            @include media-breakpoint-down(md) {
                margin-left: auto;
                margin-right: auto;
                margin-top: 0em;
                margin-bottom: 1em;
                width: 80vw;
                min-height: 50vw;
            }
        }

    }

}
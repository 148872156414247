.support-us {
    #top-landing {

        .title-background {
            margin-left: auto;
            margin-right: auto;
            max-width: 65vw;

            @include media-breakpoint-down(md) {
                min-width: 85vw;
                padding-left: 1em;
                padding-right: 1em;
                margin-left: auto;
                margin-right: auto;
            }

            h1 {
                font-size: 2.5rem;

                @include media-breakpoint-down(md) {
                    font-size: 1.5rem;
                }

            }
        }

    }

    .full-image {
        background-color: black;
        height: 60vh;
        background-image: url("../images/PlaitsInTheObservatory.jpg?width=1920");
        background-position-x: center;
        background-position-y: bottom;
        background-size: cover;
    }


    .legacy-section {
        color: black;
        background-color: white;

        .legacy-container {
            min-height: 50vh;
        }

        .quote-box {
            padding-right: 1em;
            margin-top: auto;
            margin-bottom: auto;

            @include media-breakpoint-down(md) {
                text-align: center;
                margin-bottom: 2em;
            }

        }

        .quote-title {
            text-align: right;
            padding-right: 1em;
        }

        .main-text {
            padding-left: 2.5em;
            margin-top: auto;
            margin-bottom: auto;

            @include media-breakpoint-down(md) {
                padding-left: 0em;

            }
        }


    }


    .donor-name {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        margin-left: 1.25rem;
        margin-right: 1.25rem;
        text-align: center;
    }

    .cta-section {
        color: black;
        background-color: white;

        .main-text {
            margin-top: auto;
            margin-bottom: auto;
        }

        .cta-image {
            background-color: black;
            width: 50vw;
            min-height: 30vw;
            margin-top: 0;
            margin-bottom: 0;
            margin-right: 2.5em;
            background-image: url("../images/Waiting\ for\ Sunset\ in\ South\ Africa.jpg?width=600");
            background-position-x: center;
            background-position-y: center;
            background-size: cover;

            @include media-breakpoint-down(md) {
                margin-left: auto;
                margin-right: auto;
                margin-top: 0em;
                margin-bottom: 4em;
                width: 70vw;
                min-height: 50vw;
            }
        }

    }

}
// Scrollbar Mixin: 

@mixin scroll-style($size: 15px,
    $thumb: none,
    $thumb-color: grey,
    $thumb-radius: 15px,
    $track-color: transparent,
    $track-radius: 15px) {
    scrollbar-color: $thumb-color $track-color;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
        width: $size;
        height: $size;

        &-track {
            background-color: $track-color;
            border-radius: $track-radius;
        }

        &-thumb {
            background-color: $thumb-color;
            background-image: $thumb;
            border-radius: $thumb-radius;
        }
    }
}

.scroll {
    @include scroll-style($size: var(--scroll-size, 15px),
        $thumb: var(--scroll-thumb, none),
        $thumb-color: var(--scroll-thumb-color, grey),
        $thumb-radius: var(--scroll-thumb-radius, var(--scroll-radius)),
        $track-color: var(--scroll-track, transparent),
        $track-radius: var(--scroll-track-radius, var(--scroll-radius)));

    &-1 {
        --scroll-size: 15px;
        --scroll-radius: 15px;
        --scroll-track: rgb(0 0 0 / 40%);
        --scroll-thumb-color: #dfdfdf;
    }
}

// Concept Page
.concept-page {
    #top-landing {
        background-image: url("../images/WA\ from\ a\ distance.jpg?height=800");
        background-position: top center;

    }

    .conceptpic {
        float: right;
        width: 30em;
        height: 35em;
        margin: 2em;

        @include media-breakpoint-down(lg) {
            float: none;
            max-width: 60vw;
        }

        img {
            width: 30em;
            height: 35em;
            object-fit: cover;
            object-position: top;

            @include media-breakpoint-down(lg) {
                width: 100%;
            }

        }
    }

    .technical-section {
        background-color: white;
        color: black;
        min-height: 40vh;
    }

    .concept-community-section {
        min-height: 45vh;
        background-color: white;
        color: black;
    }

    .video-section {
        background-color: black;
        color: white;
        min-height: 40vh;

        video {
            width: 100%;
            height: auto;
        }

    }

    .tl-section {

        min-height: 30em;
        padding-bottom: 10em;
        background-color: $GJW-Colour-2-hex;

        .tl-container {
            position: relative;
            overflow-x: scroll;
            margin: 2rem 0rem 2rem;
            padding-top: 1rem;
            padding-left: 0;
            padding-right: 0;
            padding-bottom: 2rem;


        }

        .tl-content {
            display: inline-flex;
            position: relative;
            padding-left: 10rem;

            @include media-breakpoint-down(lg) {
                padding-left: 1em;
            }

            &::after {
                position: absolute;
                content: "";
                height: 4px;
                left: 22rem;
                right: -10rem;
                bottom: 3.5rem;
                z-index: 1;
                background: #cccccc;
                flex: 1;

                @include media-breakpoint-down(lg) {
                    left: 13em;
                }

            }
        }

        .tl-year {
            position: relative;
            display: inline-flex;
            align-items: end;
            padding-left: 10em;
        }

        .tl-year-label {
            position: sticky;
            left: 0rem;
            bottom: 0rem;
            width: 10rem;
            min-width: 10rem;
            height: 5rem;
            min-height: 5rem;
            display: flex;
            flex-direction: column;
            justify-content: end;
            align-items: start;
            font-size: 2em;
            font-family: "Source Serif Pro", serif;
            font-weight: 300;
        }

        ul.tl-dates {
            margin-left: -9.5rem;
            margin-right: -9.5rem;
            list-style-type: none;
            display: inline-flex;
            flex-direction: row;
            flex-wrap: nowrap;
            gap: 4em;
            padding-bottom: 6em;
            margin-bottom: 0;
            margin-top: 0;
        }

        li.tl-items {
            display: flex;
            width: 20rem;
            margin-left: -11rem;
            margin-right: 11rem;
            flex-direction: column;
            justify-content: end;
            align-items: center;
            position: relative;
            text-align: center;

            &::after {
                position: absolute;
                content: "";
                bottom: -3.1rem;
                left: 10rem;
                border-style: solid;
                background-color: $GJW-Colour-2-hex;
                border: 4px solid #cccccc;
                width: 20px;
                height: 20px;
                z-index: 20;
                border-radius: 50%;
            }
        }

    }


}
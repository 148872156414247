@use 'fonts' as *;
@use 'colours' as *;

// Bootstrap Variable Overrides

$navbar-light-color: white;
$navbar-light-hover-color: $GJW-Colour-4-hex;
$navbar-light-active-color: white;
$btn-border-radius: 5px;
$accordion-color: white;
$accordion-bg: $GJW-Colour-2-hex;
$accordion-button-active-bg: tint-color($GJW-Colour-2-hex, 90%);
$accordion-button-active-color: $GJW-Colour-4-hex;
$accordion-border-color: black;


// Import all of Bootstrap's CSS
@import "node_modules/bootstrap/scss/bootstrap";


// Local Changes

body {
    background-color: black;
    color: white;
}

h1 {
    @extend .h1;
    font-family: "Source Serif Pro", serif;
    font-weight: 300;
}

h2 {
    @extend .h2;
    font-family: "Source Serif Pro", serif;
    font-weight: 300;
}

h3 {
    @extend .h3;
    font-family: "Source Serif Pro", serif;
    font-weight: 300;
}

// Navbar

.nav-item {
    font-size: 1.6rem;
    text-align: center;
}

.navbar-toggler {
    z-index: 1000;
}


@include media-breakpoint-up(lg) {
    .nav-item {
        font-size: 1.0rem;
        text-align: left;
    }

    .support-link {
        background-color: $GJW-Colour-2-hex;
        margin-left: 1em;
        padding-left: 0.5em;
        padding-right: 0.5em;
        border-radius: 4px;
    }

    .support-link:hover {
        background-color: $GJW-Colour-3-hex;
        transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out
    }

    .support-link .nav-link:hover {
        color: white;
    }

}


.footer {
    .nav-link {
        color: white;
    }

    .nav-link:hover {
        color: $GJW-Colour-4-hex;
    }
}

// Reused Elements

.sserif {
    font-family: "Source Serif Pro", serif;
    font-weight: 300;
}

#top-landing {
    min-height: 80vh;
    background-size: cover;

    .main-section {
        min-height: 60vh;
    }

}

.title-background {
    max-width: 60vw;
    background-color: rgba($color: #000000, $alpha: 0.5);

    @include media-breakpoint-down(md) {
        min-width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
}


.gjw-in-numbers {
    position: relative;
    min-height: 10em;
    overflow: visible;
    z-index: 100;
    transform: translateY(-10em);
    margin-bottom: -8em;
    padding-left: 5em;
    padding-right: 5em;

    @include media-breakpoint-down(md) {
        padding-left: 0.5em;
        padding-right: 0.5em;
        transform: translateY(-8em);
        margin-bottom: -6em;

        .gjw-in-numbers-title {
            text-align: center;
        }

    }

    .numbers-box {
        background: $GJW-Colour-5-hex-darker;
        overflow: visible;
        min-height: 10em;
        color: white;

        @include media-breakpoint-down(md) {
            padding-left: 0em;
            padding-right: 0em;
            margin: 0;
        }

    }

}

// Page Specific Changes

@import "_index";
@import "_observatories";
@import "_team";
@import "_science";
@import "_supportus";
@import "_concept";
@import "_community";

// Error Page

.error-page {
    background-color: black;
    color: white;

    #top-landing {
        .title-background {
            margin-left: auto;
            margin-right: auto;
        }
    }

    .video-section {
        background-color: black;
        color: white;
        min-height: 40vh;

        video {
            width: 100%;
            height: auto;
        }

    }

}

// Temporary Section Styling for Dev

.temp-section {
    background-color: white;
    min-height: 40vh;
    color: black;

    h1: {
        font-family: "Source Serif Pro", serif;
        font-weight: 300;
    }



}
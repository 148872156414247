.science-page {

    .title-background {
        min-width: 70vw;
        text-align: center;
    }

    section {
        min-height: 60vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    #top-landing {
        overflow: hidden;
        position: relative;
    }

    .imageholder {
        z-index: -5;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        overflow: hidden;
        background-image: url("../images/IC2944_GJW-WA_Ha_30m_full_reduced.jpg?width=1920");
        background-position: center;
        background-size: cover;
        -webkit-animation: kenburns-top 30s ease-out 0s infinite alternate forwards;
        animation: kenburns-top 30s ease-out 0s infinite alternate forwards;


    }

    .orbit-sim-im {
        height: 15em;
        min-height: unset;
        background-image: url("../images/Orbit\ Film\ Strip.jpg?width=1920");
        background-size: cover;
    }

    .spectroscopy-section {
        margin-bottom: 4em;

        .spec-image-holder img {
            width: 50vw;

            @include media-breakpoint-down(lg) {
                width: 80vw;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }

    .binary-section {
        min-height: unset;
    }

    .explosions-section {
        img {
            filter: drop-shadow(7px 7px 5px black);

            @include media-breakpoint-down(lg) {
                max-width: 100%;
            }
        }
    }

}


// Animations


/**
 * ----------------------------------------
 * animation kenburns-top
 * ----------------------------------------
 */
@-webkit-keyframes kenburns-top {
    0% {
        -webkit-transform: scale(1) translateY(0);
        transform: scale(1) translateY(0);
        -webkit-transform-origin: 50% 16%;
        transform-origin: 50% 16%;
    }

    5% {
        -webkit-transform: scale(1) translateY(0);
        transform: scale(1) translateY(0);
        -webkit-transform-origin: 50% 16%;
        transform-origin: 50% 16%;
    }

    95% {
        -webkit-transform: scale(1.25) translateY(-15px);
        transform: scale(1.25) translateY(-15px);
        -webkit-transform-origin: top;
        transform-origin: top;
    }

    100% {
        -webkit-transform: scale(1.25) translateY(-15px);
        transform: scale(1.25) translateY(-15px);
        -webkit-transform-origin: top;
        transform-origin: top;
    }
}

@keyframes kenburns-top {
    0% {
        -webkit-transform: scale(1) translateY(0);
        transform: scale(1) translateY(0);
        -webkit-transform-origin: 50% 16%;
        transform-origin: 50% 16%;
    }

    99% {
        -webkit-transform: scale(1.25) translateY(-15px);
        transform: scale(1.25) translateY(-15px);
        -webkit-transform-origin: top;
        transform-origin: top;
    }

    100% {
        -webkit-transform: scale(1.25) translateY(-15px);
        transform: scale(1.25) translateY(-15px);
        -webkit-transform-origin: top;
        transform-origin: top;
    }
}
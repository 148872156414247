// Community Page
.community-page {
    #top-landing {
        background-image: url("../images/Dancing\ at\ Sunset.jpg?height=800");
        background-position: top center;

    }

    .title-background {
        margin-left: auto;
        max-width: 50vw;
        background-color: rgba($color: #000000, $alpha: 0.7);

    }


    .student-section {
        color: white;
        background-color: black;

        .main-text {
            margin-top: auto;
            margin-bottom: auto;
        }

        .student-image {
            background-color: black;
            width: 50vw;
            min-height: 30vw;
            margin-top: 0;
            margin-bottom: 0;
            margin-right: 2.5em;
            background-image: url("../images/teaching\ in\ india.jpg?height=600");
            background-position-x: 15%;
            background-position-y: center;
            background-size: cover;

            @include media-breakpoint-down(md) {
                margin-left: auto;
                margin-right: auto;
                margin-top: 0em;
                margin-bottom: 4em;
                width: 70vw;
                min-height: 50vw;
            }
        }

    }

    .training-section {
        background-color: $GJW-Colour-1-hex;
        padding-bottom: 9rem;

        .quote-box {
            padding-right: 1em;
            margin-top: auto;
            margin-bottom: auto;

            @include media-breakpoint-down(md) {
                text-align: center;
                margin-bottom: 2em;
            }

            .quote-title {
                text-align: right;
                padding-right: 0.5em;
            }


        }


        .main-text {
            padding-left: 2.5em;
            margin-top: auto;
            margin-bottom: auto;

            @include media-breakpoint-down(md) {
                padding-left: 0em;
            }
        }
    }

    .community-section {

        color: black;
        background-color: white;


        .main-text {
            margin-top: auto;
            margin-bottom: auto;
            flex-shrink: 1;
        }

        .community-image {
            flex-shrink: 0;
            background-color: black;
            width: 35vw;
            height: 35vw;
            border-radius: 50%;
            margin-top: auto;
            margin-bottom: auto;
            margin-right: 0em;
            background-image: url("../images/bringing\ steel.jpg?width=600");
            background-position-x: center;
            background-position-y: center;
            background-size: cover;

            @include media-breakpoint-down(lg) {
                margin-left: auto;
                margin-right: auto;
                margin-top: 2em;
                margin-bottom: 0em;
                width: 70vw;
                height: 70vw;
            }
        }

    }

    .public-section {
        color: white;
        background-color: black;

        .main-text {
            margin-top: auto;
            margin-bottom: auto;
            flex-shrink: 1;
        }

        .public-image {
            flex-shrink: 0;
            background-color: black;
            width: 35vw;
            height: 35vw;
            margin-top: auto;
            margin-bottom: auto;
            margin-left: 2.5em;
            background-image: url("../images/books.jpg?width=600");
            background-position-x: center;
            background-position-y: center;
            background-size: cover;

            @include media-breakpoint-down(md) {
                margin-left: auto;
                margin-right: auto;
                margin-top: 0em;
                margin-bottom: 4em;
                width: 70vw;
                min-height: 50vw;
            }
        }


    }

    .gresham-section {
        background-color: $GJW-Colour-2-hex;
        color: white;

        .gresham-title {
            max-width: 60em;
        }

        .gresham-container {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: center;
            gap: 1em;
        }

        h2 button.accordion-button {
            text-align: center;
            font-size: 1.9rem;
            font-family: "Source Serif Pro", serif;
            font-weight: 300;
        }

        button::after {
            position: absolute;
            z-index: 100;
            right: 16px;
        }

        .card {
            background-color: $GJW-Colour-2-hex;
            border-color: black;
            max-width: 450px;

            .row {
                height: 100%;
            }

            .card-im {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: left;
            }

            .card-title {
                color: white;
                text-decoration: white underline;

                &:hover {
                    color: $GJW-Colour-4-rgba;
                    text-decoration: $GJW-Colour-4-rgba underline;
                }
            }

        }
    }

}
// Observatories Page
.observatories-page {
    #top-landing {
        background-image: url("../images/Observatory with Sky - CL.jpg?width=1920");
        background-position: bottom;

    }

    .agile-section {
        color: black;
        background-color: white;

        .agile-image {
            float: none;
            background-color: black;
            width: 100%;
            height: 35vh;
            margin-top: 1em;
            margin-bottom: 1em;
            background-image: url("../images/aquila.jpg?width=1000");
            background-position-x: center;
            background-position-y: center;
            background-size: cover;

            @include media-breakpoint-down(md) {
                float: none;
                margin-left: auto;
                margin-right: auto;
                margin-top: 0em;
                margin-bottom: 2em;
                width: 100%;
                min-height: 50vw;
            }

        }
    }

    .map-section {
        background-color: #444444;
        padding-bottom: 10em;
    }

    .map-box {
        position: relative;

        .obs-desc {
            position: absolute;
            display: inline;
        }

        #cl {
            bottom: 17%;
            left: 18%;
            max-width: 10em;

        }

        #ox {
            top: 5%;
            right: 50%;
            max-width: 20em;
        }

        #sa {
            bottom: 17%;
            left: 35%;
            max-width: 18em;
        }

        #in {
            bottom: 50%;
            right: 20%;
            max-width: 18em;
        }

        #wa {
            bottom: 10%;
            right: 20%;
            max-width: 18em;
        }

        #oz {
            bottom: 10%;
            right: 10%;
            max-width: 10em;
        }


        .obs-desc .text {
            display: inline-block;
            padding: 0.5em;
            background-color: rgba($GJW-Colour-5-hex-darker, 0.7);
            visibility: hidden;
        }

        .obs-desc:hover .text {
            visibility: visible;
            transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out
        }

        @include media-breakpoint-down(md) {
            .obs-desc {
                position: static;
                display: inline;

                #cl,
                #ox,
                #sa,
                #in,
                #wa,
                #oz {
                    max-width: unset;

                }

                .text {
                    visibility: visible;
                    margin-top: 1em;
                }
            }
        }

    }

    svg.map-outline {
        width: 100%;

        .map-pin {
            fill: $GJW-Colour-5-hex;
        }

    }


    .infrastructure-section {
        color: white;
        background-color: $GJW-Colour-1-hex;

        .main-text {
            margin-top: auto;
            margin-bottom: auto;
            flex-shrink: 1;
        }

        .infrastructure-image {
            float: right;
            background-color: black;
            width: 35vw;
            height: 35vh;
            margin-top: auto;
            margin-bottom: auto;
            margin-left: 2.5em;
            background-image: url("../images/SA-solarfarm.jpg?width=600");
            background-position-x: center;
            background-position-y: center;
            background-size: cover;

            @include media-breakpoint-down(md) {
                float: none;
                margin-left: auto;
                margin-right: auto;
                margin-top: 0em;
                margin-bottom: 2em;
                width: 100%;
                min-height: 50vw;
            }
        }

        .cable-image {
            background-color: black;
            width: 100%;
            height: 35vh;
            margin-top: 2em;
            margin-bottom: auto;
            margin-left: auto;
            background-image: url("../images/Testing\ Cables.jpg?width=1000");
            background-position-x: center;
            background-position-y: center;
            background-size: cover;

            @include media-breakpoint-down(md) {
                width: 100%;
                min-height: 50vw;
            }
        }

    }

}